<template>
  <Layout :bgColor="true">
    <h1 class="tx-c tltle">活动资讯</h1>
    <div class="slider rule-width pos-rel">
      <div class="swiper-container"
           style="width:97%">
        <div class="swiper-wrapper"
             v-if="newsList">
          <div class="swiper-slide"
               v-for="(item,index) in newsListTop"
               :key="index">
            <div class="box pos-rel flex flex-ae"
                 @click="newsDetail(item)">
              <div class="shade pos-abs"></div>
              <img :src="item.picture_domain + item.cover_url"
                   class="pos-abs"
                   alt="">
              <div class="content tx-l">
                <h4 class="tx-flow">{{item.title}}</h4>
                <h5 class="tx-flow content-center">{{item.introduction}}
                  <!-- v-html="item.content" -->
                </h5>
              </div>
            </div>
          </div>
          <!-- 
        <div class="swiper-slide">
          <div class="box"
               style="background-color: #987654;">
          </div>
        </div> -->
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
        <!-- 滚动条 -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </div>
      <!-- 导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="news rule-width">
      <div class="news-list flex flex-sb"
           @click="newsDetail(item)"
           v-for="(item,index) in newsList"
           :key="index">
        <div class="flex">
          <div class="time">
            <p class="year">{{ item.release_time | parse('y')}}</p>
            <p class="month">{{ item.release_time | parse('MM')}}-{{ item.release_time | parse('d')}}</p>
            <!-- <p class="year"
               style="font-size:12px">{{ item.release_time | parse('time')}}</p> -->
          </div>
          <div class="content">
            <h3 class="tx-flow">{{item.title}}</h3>
            <p class="content-center">{{item.introduction}}</p>
            <!-- v-html="item.content" -->
          </div>
        </div>
        <div class="img">
          <img :src="item.picture_domain + item.cover_url"
               alt="">
        </div>
      </div>
      <div class="tx-c"
           v-if="newsList.length < total">
        <h4 class="more-btn"
            @click="moreNews()">加载更多...</h4>
      </div>
    </div>

  </Layout>
</template>
<script>
import Mixins from './mixins/mixins'
import Layout from '@/components/layout/index'

export default {
  components: {
    Layout,
  },
  mixins: [Mixins],
  data() {
    return {}
  },
  methods: {
    newsDetail(item) {
      this.$router.push({
        name: 'activityDetail',
        query: { id: item.id },
      })
    },
  },
  // watch: {
  //   newsListTop() {
  //     setTimeout(() => {
  //       new Swiper('.swiper-container', {
  //         loop: true, //无缝轮播
  //         // 如果需要分页器
  //         pagination: '.swiper-pagination',
  //         // 如果需要前进后退按钮
  //         nextButton: '.swiper-button-next',
  //         prevButton: '.swiper-button-prev',
  //         mousewheelControl: true, //开启鼠标滚轮控制
  //         // 如果需要滚动条
  //         // scrollbar: '.swiper-scrollbar',
  //         //如果需要自动切换海报
  //         // autoplay: {
  //         //   delay: 10000, //时间 毫秒
  //         //   disableOnInteraction: false, //用户操作之后是否停止自动轮播默认true
  //         // },
  //         autoplay: 5000,
  //         slidesPerView: 3,
  //         spaceBetween: 10,
  //         // loopedSlides: 8,
  //       })
  //     })
  //   },
  // },
}
</script>

<style lang="scss" scoped>
.tltle {
  margin: 70px 0 30px 0;
}
.news {
  height: auto;
  overflow: auto;
  padding-bottom: 180px;
  margin-top: 150px;
  .more-btn {
    cursor: pointer;
    color: rgb(151, 151, 151);
    margin-top: 100px;
  }
  .search-box {
    margin: 30px 0;
    .search {
      width: 300px;
      background: #fff;
      color: gray;
      padding: 6px 12px;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .news-list {
    margin-bottom: 30px;
    border-radius: 20px;
    cursor: pointer;
    padding: 20px;
    &:hover {
      background: rgb(245, 245, 245);
    }
    .time {
      width: 220px;
      .year {
        font-size: 24px;
        color: rgba(#000000, 0.5);
      }
      .month {
        font-size: 48px;
        line-height: 72px;
      }
    }
    .content {
      max-width: 470px;
      h3 {
        margin: 0 0 10px 0;
        font-weight: bold;
      }
      p {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
        color: #333;
        line-height: 30px;
        text-overflow: -ms-ellipsis;
        text-overflow: -o-ellipsis-lastline;
        line-clamp: 4;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
      }
      .content-center {
        & /deep/ img {
          display: none;
        }
      }
    }
    .img {
      width: 360px;
      height: 236px;
      background: #f3f3f3;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.slider {
  margin-bottom: 30px;
  min-height: 200px;
  // .swiper-container {
  //   height: auto;
  //   width: 100%;
  // }
  .swiper-wrapper .swiper-slide {
    // width: 30%;
    // height: 100%;
    text-align: center;
  }
  .swiper-button-next {
    right: -30px;
  }
  .swiper-button-prev {
    left: -30px;
  }
  .box {
    height: 236px;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;
    .shade {
      width: 100%;
      height: 100%;
      background: rgba(#000000, 0.5);
      top: 0;
      right: 0;
      z-index: -1;
    }
    .content {
      margin: 10px 20px;
      width: calc(100% - 40px);
      box-sizing: border-box;
      h4 {
        color: #fff;
        margin-bottom: 5px;
        font-weight: bold;
      }
      h5 {
        width: 100%;
        color: #fff;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
      }
      .content-center {
        & /deep/ img {
          display: none;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      right: 0;
      z-index: -2;
    }
  }
}
</style>
